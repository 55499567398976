import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import Axios from 'axios'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.config.globalProperties.$http = Axios;
app.config.globalProperties.$http.defaults.headers.common['Source-System'] = 'signage';

app.config.globalProperties.$filters = {
  truncate(value, size) {
    if(!value) return '';
    value = value.toString();

    if(value.length <= size) {
      return value;
    } else {
      return value.substr(0, size) + '...';
    }
  },
  abbr(points) {
    if(String(points).length >= 7) {
      if(points % 1 != 0) {
        return (points/1000000).toFixed(1) + 'M';
      } else {
        return (points/1000000) + 'M';
      }
    } else if(String(points).length >= 4) {
      if(points % 1 != 0) {
        return (points/1000).toFixed(1) + 'K';
      } else {
        return (points/1000) + 'K';
      }        
    } else {
      return points;
    }
  }  
}

app.mount('#app')
